.one-rep-max-calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;
}

.input-group label {
  margin-bottom: 0.5rem;
}

.input-group input {
  max-width: 100px;
  margin: 0 auto;
  display: block;
}

.input-with-select {
  display: flex;
  width: 100%;
}

.input-with-select input {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.one-rep-max-calculator input,
.one-rep-max-calculator select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.one-rep-max-calculator button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.one-rep-max-calculator button:hover {
  background-color: #0056b3;
}

.result {
  text-align: center;
}

@media (max-width: 480px) {
  .one-rep-max-calculator {
    max-width: 100%;
    padding: 0 1rem;
  }
}
