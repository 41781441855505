/* App.css */

/* BASE STYLES */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* border: 1px solid green; */
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: var(--primary-color);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
}
ul {
  list-style-type: none;
}

/* LAYOUT STYLES */
.App {
  text-align: center;
  max-width: 600px;
  margin: 2rem auto;
  background-color: #fff;
  background-image: linear-gradient(to top, #e0e0e0 0%, #f9f9f9 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 8px;
}

h1,
h2 {
  margin-bottom: 1.5rem;
}

.calculator-toggle {
  margin-bottom: 2rem;
}

/* MODULE STYLES */
label {
  display: block;
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 1.5rem;
}

input,
select {
  width: 70%;
  max-width: 300px;
  padding: 0.5rem;
  border: 2px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
  outline: none;
}

input,
number {
  text-align: center;
  width: 80px;
}

.small-input {
  width: 100px;
}

.select-bar-weight {
  width: 170px;
}

.avisos {
  margin-top: 50px;
}

button {
  padding: 0.5rem 0.75rem;
  background-color: #19283b;
  color: #fff;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

/* STATE STYLES */
input:focus,
select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0 25);
}

button:hover {
  background-color: #0056b3;
  color: white;
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

li:last-child {
  border-bottom: none;
}

/* PLATE STYLES (MODULE) */
.plate {
  font-weight: bold;
  display: inline-block;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.plate-20,
.plate-45 {
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
}

.plate-15,
.plate-35 {
  background-color: #ffc107;
  color: #fff;
  border-radius: 3px;
}

.plate-10,
.plate-25 {
  background-color: #28a745;
  color: #fff;
  border-radius: 3px;
}

.plate-5,
.plate-10_lbs,
.plate-1,
.plate-1_25 {
  background-color: #ffffff;
  color: #333;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.plate-2_5,
.plate-5_lbs {
  background-color: #dc3545;
  color: #fff;
  border-radius: 3px;
}

.plate-1_5 {
  background-color: #ffc107;
  color: #fff;
  border-radius: 3px;
}

.plate-2_5_lbs {
  background-color: #28a745;
  color: #fff;
  border-radius: 3px;
}

.plates-container {
  padding: 0.5rem 0;
  font-size: 0.9rem;
}

.plates-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin: 16px 0;
  padding: 0 16px;
}

.plates-selection label {
  display: inline-block;
  padding: 2px 2px;
}

.plate-checkbox {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.plate-checkbox:hover {
  background-color: #e8e8e8;
}

.plate-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}

.plate-checkbox input[type="checkbox"]:checked {
  background-color: #007bff;
}

.plate-checkbox input[type="checkbox"]:checked::after {
  content: "✓";
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.remove-plate-btn {
  background: #f0f0f0;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  margin-left: 8px;
  opacity: 1;
  transition: all 0.2s ease;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-plate-btn:hover {
  color: white;
  background: #dc3545;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.plate-checkbox:hover .remove-plate-btn {
  opacity: 1;
}

.custom-warmup-series {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.custom-warmup-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.custom-warmup-series .input-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.warmup-series-container {
  margin: 10px 0;
}

.warmup-set {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.warmup-set strong {
  font-weight: 700;
}

.target-weight {
  display: inline-block;
}

.donation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.donation-image {
  width: 150px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 15px;
}

.donation-text {
  text-align: center;
  margin-bottom: 15px;
}

.donation-text h3 {
  color: #333;
  margin-bottom: 10px;
}

.donation-text p {
  color: #666;
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .App {
    margin: 1px auto;
    padding: 1rem;
  }

  input,
  select {
    width: 100%;
    max-width: 250px;
  }

  label {
    font-size: 1rem;
  }

  .plates-container {
    font-size: 0.8rem;
  }
}

.target-weight {
  display: block;
  margin-left: 30%;
  align-items: center;
  width: 80px;
}

.feedback-footer {
  /* position: fixed; */
  /* bottom: 10px; */
  margin-top: 10px;
  right: 10px;
  font-size: 0.8em;
  opacity: 0.7;
}

.feedback-link {
  color: #666;
  text-decoration: none;
}

.feedback-link:hover {
  text-decoration: underline;
}

.pr-tracker {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.pr-tracker form {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.pr-tracker ul {
  list-style-type: none;
  padding: 0;
}

.pr-tracker li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.calculator-toggle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.calculator-toggle button {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.calculator-toggle button:hover {
  background-color: #e0e0e0;
}

.calculator-toggle button.active {
  background-color: #007bff;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* NEW FEEDBACK FORM STYLES */
.feedback-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}

.feedback-toggle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.feedback-toggle:hover {
  background-color: #0056b3;
}

.feedback-form {
  position: absolute;
  bottom: 50px;
  right: 0;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.feedback-form h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.feedback-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.feedback-form button {
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.feedback-form button:hover {
  background-color: #218838;
}

/* Custom plate input styles */
.custom-plate-input {
  margin-top: 20px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.custom-plate-input input {
  width: 100px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  text-align: center;
}

.custom-plate-input button {
  padding: 6px 16px;
}

/* Add these styles */
.warmup-heading {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
  margin-bottom: 1.5rem;
  position: relative;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: baseline;
  height: 100%;
  margin-top: 4px;
}

.info-icon {
  color: #666;
  font-size: 1.5rem;
  cursor: help;
  vertical-align: middle;
  transform: translateY(-2px);
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.9rem;
  line-height: 1.4;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

